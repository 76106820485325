//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
import ProductColorCarousel from "@/components/pages/store/StoreListItem/Components/ProductColorCarousel";
import ListProductPrice from "@/components/pages/store/StoreListItem/Components/ListProductPrice";
import { mapGetters } from "vuex";
import addToWishlist from '~/assets/js/eSputnik/addToWishlistEvent';
import bnpl from '~/assets/img/icons/0-0-4.svg?raw'

export default {
    name: "StoreListItem",
    components: { ListProductPrice, ProductColorCarousel, ResponsiveImage },
    props: {
        product: {
            type: Object,
            required: true,
        },
        position: {
            type: [Number, String],
            default: 1
        },
        loading: {
            type: Boolean,
            required: true
        },
        listName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            hoverNow: false,
            productColorInfo: undefined,
            imageHovered: false,
            bnplIcon: bnpl
        };
    },
    computed: {
        ...mapGetters({
            isProductFavorite: 'favorite/isProductFavorite',
        }),
        colorsList() {
            return [
                ...(this.product?.colors ?? [])
            ];
        },
        colorsCount() {
            return (this.colorsList?.length ?? 0);
        },
        isDesktop() {
            return this.$breakpoint.laptopSmallUp;
        },
        isFavorite() {
            return this.isProductFavorite(this.product.id);
        },
        showColors() {
            return this.isDesktop && this.colorsCount > 1;
        },
        activeHoveredProduct() {
            return (this.productColorInfo ?? this.product);
        },
        showedInfo() {
            return {
                ...this.activeHoveredProduct,
                plpImage: {
                    ...(this.activeHoveredProduct?.imagesMainList?.plp ?? {}),
                    media: this.getImageUrl(
                        (this.activeHoveredProduct?.imagesMainList?.plp?.id ?? ''),
                        (this.activeHoveredProduct?.imagesMainList?.image?.title ?? ''),
                        (this.activeHoveredProduct?.imagesMainList?.image?.alt ?? '')
                    )
                },
                hoverImage: {
                    ...(this.activeHoveredProduct?.imagesMainList?.hover ?? {}),
                    media: this.getImageUrl(
                        (this.activeHoveredProduct?.imagesMainList?.hover?.id ?? ''),
                        (this.activeHoveredProduct?.imagesMainList?.image?.title ?? ''),
                        (this.activeHoveredProduct?.imagesMainList?.image?.alt ?? '')
                    )
                }
            };
        },
        priceWobbler: function () {
            if (Array.isArray(this.showedInfo.wobblers)) {
                return this.showedInfo.wobblers.find(el => el.type && el.type === 'discount') || null;
            }else {
                return null;
            }
        },
        wobblerBNPL() {
            if (Array.isArray(this.showedInfo.wobblers)) {
                return this.showedInfo.wobblers.find(el => el.type === 'bnpl') || '';
            } else {
                return '';
            }
        },
        showImage() {
            return (this.showedInfo?.hoverImage?.media ?? null) || (this.showedInfo?.plpImage?.media ?? null);
        },
        mainImage() {
            return (this.showedInfo?.plpImage?.media ?? null) || (this.showedInfo?.hoverImage?.media ?? null);
        },
        hoverImage() {
            return (this.showedInfo?.hoverImage?.media ?? null) || (this.showedInfo?.plpImage?.media ?? null);
        },
        isShowImageOverlay() {
            return this.activeHoveredProduct.hasOwnProperty('isAvailable') && !this.activeHoveredProduct.isAvailable;
        },
        isHoverOverlay() {
            return this.hoverNow && this.isShowImageOverlay;
        },
    },

    methods: {
        getImageUrl(id, title, alt) {
            return ImageHelper.getMediaStructure(id, AppConfig.image.fileExtensions.catalog, title, alt, ImageSizes.catalogMain, 80);
        },
        setImageHoverState(state = false) {
            this.imageHovered = state;
        },
        setProductColorInfo(data) {
            this.productColorInfo = data;
        },
        hoverIn() {
            if (this.$breakpoint.laptopSmallUp && this.colorsCount > 1 && !this.hoverNow && this.$refs.product && this.$refs.productContent) {
                this.$refs.product.style.height = this.$refs.productContent.clientHeight + 2 + 'px';
            }
            this.hoverNow = true;
        },
        hoverOut() {
            this.hoverNow = false;
            this.$refs.product && (this.$refs.product.style.height = 'initial');
        },
        toggleFavorite() {
            if (this.isFavorite) {
                this.$store.dispatch('favorite/removeFromFavorite', this.product.id);
            } else {
                this.$store.dispatch('favorite/addToFavorite', this.product.id);
                addToWishlist(this.product);
                this.$gtag('event', 'add_to_wishlist', {
                    value: this.product.price.sale,
                    items: this.product.sizes.map(i => {
                        return {
                            id: i.id,
                            google_business_vertical: 'retail'
                        };
                    })
                });
                window.fbq && window.fbq('track', 'AddToWishlist', {
                    content_type: 'product',
                    value: this.product.price.sale,
                    currency: 'KZT',
                    content_ids: [this.product.id],
                });
                // this.$ga.sendSimpleEvent('add_to_wishlist')
            }
        },
        saveListName() {
            this.$ga.onProductInListClick(this.product.id, this.position);
        },
        sendProductClick() {
            this.saveListName();
            this.$ga.sendProductClick({
                name: this.product.displayName,
                id: this.product.id,
                price: this.product.price.sale,
                brand: this.product.division,
                category: this.product.productPath || [],
                variant: 'adidas',
                position: this.position,
                list: this.listName
            });
        },
        onMouseUp(e) {
            if (e.button === 1) {
                this.sendProductClick();
            }
        },
        mainProductClickHandler() {
            this.sendProductClick();
            this.saveProductClickedId();
        },
        productCarouserClickHandler() {
            this.saveProductClickedId();
        },
        saveProductClickedId() {
            window.sessionStorage.setItem("elementToScroll", this.showedInfo.id);
        },
    },

};
